import { Link } from "@inertiajs/react";
import { getIcon } from "./navDropdown";
import { NavPageInterface } from "./nav";

export const ConditionalLink = ({ inertia, href, ...props }) =>
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    inertia ? <Link href={href} {...props} /> : <a href={href} {...props} />;

export default function MobileNavTopItem({ item }: { item: NavPageInterface }) {
    const Icon = getIcon(item?.page?.icon);
    return (
        <ConditionalLink
            inertia={item?.page?.inertia}
            href={item.page.url}
            className="-m-3 flex items-center rounded-md p-2 hover:bg-gray-50"
        >
            <Icon className="size-6" />
            <span className="ml-3 text-base font-medium text-gray-900">
                {item.page.title}
            </span>
        </ConditionalLink>
    );
}
