import { useState } from "react";
import Modal from "./modal";
import Button from "../buttons/button";
import { router, usePage } from "@inertiajs/react";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

export default function TermsModal({ open }) {
    const { auth } = usePage().props;
    const [accepted, setAccepted] = useState(true);
    const [error, setError] = useState(false);
    const confirmConsent = () => {
        if (accepted) {
            router.post(route("user.consent.update", auth.user.id), {
                registration: {
                    participation:
                        dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS") + "000Z",
                    equality_diversity_inclusion:
                        dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS") + "000Z",
                    anonymised_aggregation_of_data:
                        dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS") + "000Z",
                    development_and_or_improvement_of_ai_tools:
                        dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS") + "000Z",
                },
            });
        } else {
            setError(true);
        }
    };
    return (
        <Modal open={open} title="Updates to our Terms & Privacy Policy">
            <div className="text-sm flex flex-col">
                <p>
                    <FormattedMessage id="We're constantly looking for ways to improve the Voice experience and to ensure that our policies explain how our services work. So we're letting you know about a revision to our Terms of Use and Privacy Policy. These will come into effect on November 28th, 2024 so please read these carefully:" />
                </p>
                <ul className="list-disc pl-4 py-4 font-medium">
                    <li>
                        <a href="/terms-of-use" className="underline">
                            <FormattedMessage id="Terms of Use" />
                        </a>
                    </li>
                    <li>
                        <a href="/privacy-policy" className="underline">
                            <FormattedMessage id="Privacy Policy" />
                        </a>
                    </li>
                </ul>
                <div className="flex gap-x-4">
                    <input
                        type="checkbox"
                        className="size-5 rounded-sm border-gray-300 text-black focus:ring-black"
                        checked={accepted}
                        onChange={(e) => setAccepted(e.target.checked)}
                    />
                    <p>
                        <FormattedMessage id="By checking this box, I confirm that I have reviewed and agree to the Terms of Use, acknowledge the Privacy Policy and consent to the processing of my special category data as outlined in the Privacy Policy. I am at least 18 years of age." />
                    </p>
                </div>
                {error && (
                    <span className="text-red-600 mt-4">
                        <FormattedMessage id="These terms must be accepted to continue using the platform" />
                    </span>
                )}
                <div className="flex justify-end pt-4">
                    <Button color="black" onClick={confirmConsent}>
                        <FormattedMessage id="Confirm" />
                    </Button>
                </div>
                <p className="text-xs pt-4 text-gray-600">
                    <FormattedMessage id="For queries relating to any changes made to these terms, or should you wish to cancel your account, please contact" />{" "}
                    <a
                        href="mailto:hello@voice-global.org"
                        className="text-red-600"
                    >
                        hello@voice-global.org
                    </a>
                </p>
            </div>
        </Modal>
    );
}
